formFuncs['confOrder'] = function(){
    var form = $('.order-user'),
        results = get_fields(form),
        errors = 0;

    results['delivery'] = 1;
    results['delivery_price'] = 0;
    result['payment'] = $('#kaspi').prop('checked');

    $('#order_cash_sum').each(inputCheck);

    if(result['payment']){
        result['payment'] = 1;
        results['cash_sum'] = 0;
    }else{
        result['payment'] = 0;

        $('#order_cash_sum').each(inputCheck);

        if($('#order_cash_sum').parents('.form-group').hasClass('error')){
            errors++;
        }else{
            results['cash_sum'] = $('#order_cash_sum').val();
        }

    }

    if($('#delivery').prop('checked')){

        if(deliveryAddress && inZone){
            results['address'] = deliveryAddress;
            results['delivery'] = 0;
            results['delivery_price'] = deliveryPrice;
        }else{
            errors++;
        }
        
    }
    
    if(!errors){
        orderLoading();
    
        sendAjax('add_order', results, function(res){
            simpleAjax('clean_cart', function(){
                window.location.href = '/success';
            })
        },function(res){
            order_body.find('.message-block').remove();
            order_body.prepend(print_msg('error', 'Вы не можете оформить заказ', 'При оформлении заказа произошла неизвестная ошибка'));
            orderLoadingOff();
        })
    }

};

$('.form-group input').change(function(){

    if($('#cash').prop('checked')){
        $('.form-group.cash').stop().removeClass('error').slideDown(300);
    }else{
        $('.form-group.cash').stop().removeClass('error').slideUp(300);
    }

});

function orderLoading(){
    $('.order-body').addClass('loading');
    $('.bind-to-form').addClass('thinks');
};

function orderLoadingOff(){
    $('.order-body').removeClass('loading');
    $('.bind-to-form').removeClass('thinks');
};

$('input#pickup, input#delivery').on('change', function(){
    var elem = $(this),
        id = elem.attr('id'),
        price = [0,0,0];

    orderLoading();

    if(id == 'delivery'){
        price[0] = deliveryPrice;
        price[1] = minPrice;
    }

    requestAjax('get_order_sidebar', price, function(res){
        orderSidebar.find('.parametrs-list').remove();
        orderSidebar.prepend(res);
        orderLoadingOff();
    });
});