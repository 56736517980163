$('.toggle-block .tab').click(function(){
    var elem = $(this),
        name = elem.data('name'),
        parent = elem.parents('.toggle-block');

    parent.find('.active').removeClass('active');
    parent.find('[data-name="' + name + '"]').addClass('active');
});

$('.toggle-show').click(function(){
    var elem = $('.' + $(this).data('show'));
    elem.toggleClass('show');
});

$('.remove-show').click(function(){
    var elem = $('.' + $(this).data('show'));

    elem.removeClass('show');
});

$('.add-show').click(function(){
    var elem = $('.' + $(this).data('show'));

    elem.addClass('show');
});

var groups = [];

$('.outside-hide').each(function(){
    var group = $(this).data('group'),
        elems = $('[data-group="' + group + '"]'),
        elem = $('.' + $(this).data('show'));

    if($.inArray( group, groups ) == '-1'){
        groups.push(group);

        $(document).click(function (e){
            if (!elems.is(e.target) && elems.has(e.target).length === 0) {
                elem.removeClass('show');
            }
        });
    }

});

$('.switchable-tabs .tab').click(function(){
    var elem = $(this),
        parent = elem.parent(),
        items = parent.children(),
        index = items.index(elem),
        group = parent.data('group'),
        content = $('.switchable-blocks[data-group="' + group + '"]').children().eq(index);

    content.siblings().removeClass('active');
    elem.siblings().removeClass('active');

    content.addClass('active');
    elem.addClass('active');

    content.find('.slick-slider').slick('setPosition');
});