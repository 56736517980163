if( $('#delivery-map').length > 0 ){
    ymaps.ready(initDeliveryMap);
}

var deliveryZones,
    deliveryAddress = '',
    deliveryCoords,
    inZone = 0,
    deliveryPrice = 0,
    minPrice = 0,
    orderSidebar = $('.order-sidebar .content-block-body');

function initDeliveryMap() {
    var myPlacemark,
        defCoords = $('#delivery-map').data('coords'),
        myMap = new ymaps.Map('delivery-map', {
            center: [76.91612604467768,43.24468006977554],
            zoom: 13,
            controls: ['geolocationControl', 'searchControl']
        }),
        deliveryPoint = new ymaps.GeoObject({
            geometry: {type: 'Point'},
            properties: {iconCaption: 'Адрес'}
        }, {
            preset: 'islands#blackDotIconWithCaption',
            draggable: true,
            iconCaptionMaxWidth: '215'
        }),
        searchControl = myMap.controls.get('searchControl');

    searchControl.options.set({noPlacemark: true, placeholderContent: 'Введите адрес доставки'});
    myMap.geoObjects.add(deliveryPoint);

    function onZonesLoad(json) {
        // Добавляем зоны на карту.
        deliveryZones = ymaps.geoQuery(json).addToMap(myMap);
        // Задаём цвет и контент балунов полигонов.
        deliveryZones.each(function (obj) {
            obj.options.set({
                fillColor: obj.properties.get('fill'),
                fillOpacity: obj.properties.get('fill-opacity'),
                strokeColor: obj.properties.get('stroke'),
                strokeWidth: obj.properties.get('stroke-width'),
                strokeOpacity: obj.properties.get('stroke-opacity')
            });

            obj.events.add('click', function (e) {       
                var coords = e.get('coords');

                myPlacemark = createPlacemark(coords);
                highlightResult(myPlacemark);
                
            });

        });

        // Проверим попадание результата поиска в одну из зон доставки.
        searchControl.events.add('resultshow', function (e) {
            highlightResult(searchControl.getResultsArray()[e.get('index')]);
        });

        // Проверим попадание метки геолокации в одну из зон доставки.
        myMap.controls.get('geolocationControl').events.add('locationchange', function (e) {                                
            highlightResult(e.get('geoObjects').get(0));
        });

        // При перемещении метки сбрасываем подпись, содержимое балуна и перекрашиваем метку.
        deliveryPoint.events.add('dragstart', function () {
            deliveryPoint.properties.set({iconCaption: '', balloonContent: ''});
            deliveryPoint.options.set('iconColor', 'black');
            
        });

        // По окончании перемещения метки вызываем функцию выделения зоны доставки.
        deliveryPoint.events.add('dragend', function () {
            highlightResult(deliveryPoint);
        });

        function highlightResult(obj) {
            // Сохраняем координаты переданного объекта.
            var coords = obj.geometry.getCoordinates(),
            // Находим полигон, в который входят переданные координаты.
            contain = deliveryZones.searchContaining(coords),
            polygon = contain._objects.pop();
            // Проверяем в зоне бесплатной доставки ли
            
            if (polygon) {
                // Уменьшаем прозрачность всех полигонов, кроме того, в который входят переданные координаты.
                deliveryZones.setOptions('fillOpacity', 0.4);
                polygon.options.set('fillOpacity', 0.8);
                // Перемещаем метку с подписью в переданные координаты и перекрашиваем её в цвет полигона.
                deliveryPoint.geometry.setCoordinates(coords);
                deliveryPoint.options.set('iconColor', polygon.properties.get('fill'));
                // Задаем подпись для метки.
                if (typeof(obj.getThoroughfare) === 'function') {
                    setData(obj);
                    
                } else {
                    // Если вы не хотите, чтобы при каждом перемещении метки отправлялся запрос к геокодеру,
                    // закомментируйте код ниже.
                    ymaps.geocode(coords, {results: 1}).then(function (res) {
                        var obj = res.geoObjects.get(0);
                        setData(obj);
                    });
                    
                }

                inZone = 1;
                $('#delivery-map').removeClass('error');
            } else {
                myMap.balloon.open(coords, "Доставка в этой зоне не доступна");
            }            

            function setData(obj){                
                var address = obj.getAddressLine(),
                    price = polygon.properties.get('description').split(',');

                simpleAjax('get_cart_total', function(cart_total){;
                    cart_total = JSON.parse(cart_total);

                    var total = cart_total.price;
                    if(total >= price[2]){
                        price[0] = 0;
                    }

                    if(total < price[1]){
                        $('.confirm-order-btn').attr('disabled', true);
                        $('.min-price span').text(price[1] + ' тг.');
                        $('.min-price').slideDown(300);
                    }else{
                        $('.confirm-order-btn').attr('disabled', false);
                        $('.min-price').slideUp(300);
                    }

                    deliveryCoords = obj.geometry._coordinates.join(',');

                    minPrice = parseInt(price[1]);
                    deliveryPrice = parseInt(price[0]);
                    deliveryAddress = address;
    
                    if(price[0] == 0){
                        price[0] = 'Бесплатная доставка';
                    }else{
                        price[0] += ' тг.'
                    }
                    
                    $('#delivery-address').val(address);
                    $('.form-group.address').removeClass('error');
                    $('#delivery-address').attr('data-price', price[0]);

                    if(price[2]){
                        address += '<br>Бесплатная доставка от ' + price[2] + 'тг.';
                    }

                    if(price[1]){
                        address += '<br>Минимальная сумма заказа ' + price[1] + 'тг.';
                    }

                    deliveryPoint.properties.set({
                        iconCaption: address,
                        balloonContent: address,
                        balloonContentHeader: price[0]
                    });
                    deliveryPoint.balloon.open();

                    price[0] = deliveryPrice;
                    
                    if(orderSidebar.length){
                        orderLoading();
                        requestAjax('get_order_sidebar', price, function(res){
                            orderSidebar.find('.parametrs-list').remove();
                            orderSidebar.prepend(res);
                            orderLoadingOff();
                        });
                    }

                });
                
            }

            myMap.setCenter(coords, 17);
            
        }

        // Создание метки.
        function createPlacemark(coords) {
            return new ymaps.Placemark(coords, {
                iconCaption: 'поиск...'
            }, {
                preset: 'islands#violetDotIconWithCaption',
                draggable: true,
                iconCaptionMaxWidth: '1000',
                hasBalloon : false
            });
        }

        myMap.events.add('click', function (e) {       
            var coords = e.get('coords');

            myPlacemark = createPlacemark(coords);
            highlightResult(myPlacemark);

        });        

        suggestView = new ymaps.SuggestView('delivery-address', {
            zIndex: 12,
            provider: {
                suggest : function(request, options) {
                    
                    request = 'Казахстан, Алматы' + request;

                    let suggest = new ymaps.suggest( request );

                    suggest.then( function(items){

                        $.each(items, function(index, key){                                  
                            key.value = key.value.replace(/(.*?), /, '');
                            key.displayName = key.displayName.split(', ').slice(0, -1).join(', ');                                                                
                        });
                        
                    });                  
                
                    return suggest;
                }
            }
        });

        suggestView.events.add("select", function(e){
            var val = e.get('item').value;
            ymaps.geocode(val).then(function (res) {
                var myGeocoder = ymaps.geocode(val);                

                myGeocoder.then(
                    function (res) {
                        var objs = res.geoObjects.toArray();
                        var coords = objs[0].geometry._coordinates;                        
                        
                        myPlacemark = createPlacemark(coords);
                        highlightResult(myPlacemark);
                    }
                );
                
            })   
            
        })

        $('#delivery-address').on('change', function(){
            var val = $(this).val();

            ymaps.geocode(val).then(function (res) {
                var myGeocoder = ymaps.geocode(val);

                myGeocoder.then(
                    function (res) {
                        var objs = res.geoObjects.toArray();
                        var coords = objs[0].geometry._coordinates;     
                        myPlacemark = createPlacemark(coords);
                        highlightResult(myPlacemark);
                    }
                );
                
            })

        })

        if(defCoords){
            defCoords = defCoords.split(',');
            myPlacemark = createPlacemark(defCoords);
            highlightResult(myPlacemark);
        }

        $('#delivery-map').removeClass('loading');

    }

    $.ajax({
        url: '/wp-content/themes/init/data.geojson',
        dataType: 'json',
        success: onZonesLoad
    });
    
}