$(document).ready(function () {
    setTimeout(function(){
        $('.popper').fadeOut(300);

        $('.popper-wrapper').on('mouseenter', function(){
            var popper = $(this).children('.popper');
            popper.stop();
            popper.fadeIn(300);
            
        });

        $('.popper-wrapper').on('mouseleave', function(){
            var popper = $(this).children('.popper');
            popper.stop();
            popper.fadeOut(300);
        });

    }, 3000);
});