var price_range = $('#price-range'),
    pr_min = price_range.data('min'),
    pr_max = price_range.data('max'),
    pr_min_old = pr_min,
    pr_max_old = pr_max,
    pr_min_field = $('#pr_min'),
    pr_max_field = $('#pr_max'),
    filters_block = $('.filters-block'),
    fb_content = $('.fb-content'),
    tax_id = filters_block.data('tax'),
    items_wrapper = $('.catalog-items'),
    old = get_results(),
    change_available = true;

function compareArrays(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

function changePRValue(){
    pr_min_field.val(pr_min);
    pr_max_field.val(pr_max);
}

function reset_filters(){
    var block = $('.filters-block'),
        items = block.find('.filter-item'),
        checked_items = items.find('input:checked');

    checked_items.prop('checked', false);

    pr_min = pr_min_old;
    pr_max = pr_max_old;

    pr_min_field.val(pr_min);
    pr_max_field.val(pr_max);

    price_range.slider( "values", [ pr_min_old, pr_max_old ] );
}

function get_results(){
    var prices = {
            'min': pr_min,
            'max': pr_max
        },
        checked = [],
        sort = $('.archive-sort .sort-item.active');
        result = {
            prices: prices,
            tax: tax_id
        },
        items = filters_block.find('.filter-item'),
        checked_items = items.find('input:checked');

    if(sort.length){
        sort = {
            name: sort.data('name'),
            dir: sort.data('direction')
        }
    }else{
        sort = ''; 
    }

    result['sort'] = sort;

    checked_items.each(function(){
        var elem = $(this),
            id = elem.data('id');

        checked.push(id);     
    });

    result['checked'] = checked;

    return(result);
}

function filters_change(elem){
    var hint = $('.fb-accert-hint'),
        found = hint.find('.filters-found-count'),
        parent = elem.parent(),
        group = elem.parents('.filter-item'),
        offsetY = parent.offset().top - ((hint.height() / 2) - (parent.height() / 2)),
        offsetX = group.offset().left + group.width(),
        results = get_results(),
        accert = $('.fb-accert');
    
    event.preventDefault();

    hint.stop();

    if(!compareArrays(results, old)){
        fb_content.addClass('loading');
        hint.addClass('thinks');

        accert.attr('disabled', true);
        sendAjax('get_filter_count', results, function(res){
            count = parseInt(res.count);
    
            fb_content.removeClass('loading');
            
            found.text(count);
            
            if(count){
                accert.removeAttr('disabled');
                hint.removeClass('empty');
            }else{
                hint.addClass('empty');
            }
    
            hint.removeClass('thinks');
            hint.css({'top': offsetY, 'left': offsetX});
            hint.show();
        })
    }else{
        fb_content.removeClass('loading');
        hint.removeClass('thinks');
        hint.fadeOut();
    }
}

price_range.slider({
    range: true,
    values: [ pr_min, pr_max ],
    min: pr_min,
    max: pr_max,
    slide: function( event, ui ) {
        var values = ui.values;

        pr_min = values[0];
        pr_max = values[1];
        changePRValue();
    },
    change: function( event, ui ) {
        pr_min_field.change();
    }
})

$(document).on('change', '#pr_min, #pr_max', function(){
    var elem = $(this);

    if(change_available){
        var min = elem.data('min'),
            max = elem.data('max'),
            val = elem.val();

        change_available = false;

        if(!isNaN(parseFloat(val)) && isFinite(val)){
            
            if(val < min){
                elem.val(min);
            }else if(val > max){
                elem.val(max);
            }
    
            if(parseInt(pr_min_field.val()) > parseInt(pr_max_field.val())){
                pr_min_field.val(pr_max_field.val());
            }
    
        }else{
    
            if(elem.hasClass('min')){
                elem.val(min);
            }else{
                elem.val(max);
            }
    
        }
    
        pr_min = parseInt(pr_min_field.val());
        pr_max = parseInt(pr_max_field.val());

        price_range.slider('values', [pr_min, pr_max]);
        
        change_available = true;
        filters_change(elem);
    }

})

$(document).on('change', '.filter-checkbox', function(){
    var elem = $(this);

    filters_change(elem)
})

$(document).on('click', '.fb-accert', function(){
    var results = get_results(),
        elem = $(this),
        accert = $('.fb-accert'),
        reset = $('.fb-reset');

    if(!compareArrays(results, old) && !elem.hasClass('thinks')){
        old = results;
        fb_content.addClass('loading');
        items_wrapper.addClass('loading');
        accert.addClass('thinks');
        reset.addClass('thinks');

        jQuery.post('/wp-admin/admin-ajax.php', {
            action: 'get_filter_items',
            data: results
        },
            function (res) {
                fb_content.removeClass('loading');
                items_wrapper.removeClass('loading');
                accert.removeClass('thinks');
                reset.removeClass('thinks');
                
                items_wrapper.find('.catalog-item, .empty-block').remove();
                items_wrapper.append(res);
            }
        );

    }
})

$(document).on('click', '.fb-reset', function(){
    var elem = $(this),
        accert = $('.fb-accert');

    accert.removeAttr('disabled');

    if(!elem.hasClass('thinks')){
        reset_filters();
    }
})