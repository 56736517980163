formFuncs['sendMessage'] = function(){
    var form = $('.write-us-from'),
        results = get_fields(form),
        btn = $('.write-us-btn'),
        success = form.find('.success-screen');

    results['method'] = 2;
    btn.addClass('thinks');

    sendAjax('send_mail', results, function(res){
		success.fadeIn(300);
		form.find('.required').each(resetInput);	
		btn.removeClass('thinks');	
    })

};