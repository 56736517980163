var sliders = {
  'rec': {
      dots: false,
      arrows: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      slide: '.catalog-item',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
        }
      ]
  },
  'rec-l': {
      dots: false,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      slide: '.catalog-item',
      responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
          },
          {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
          }
        ]
  }
};

$('.slider-arrows .arrow').click(function(){
  var elem    = $(this),
      dir     = elem.data('direction') == 'prev' ? 'Prev' : 'Next',
      parent  = elem.parents('.section-title'),
      slider  = parent.siblings('.slick-slider');

    if(slider.length == 0){
      slider = parent.siblings().find('.slick-slider');
    }

  slider.slick('slick' + dir);
})