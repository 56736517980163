var cart = $('section.cart'),
    widget = $('.cart-widget-popup'),
    cart_add_timer;

function updateCart(){
    cart.addClass('loading');
    widget.addClass('loading');
    
    simpleAjax( "update_cart", function(res){
        var res = JSON.parse(res),
            cart_header = res.cart_header,
            cart_items = res.cart_items,
            widget_items = res.widget_items,
            widget_label = res.widget_label,
            cart_total = res.cart_total;

        $('.cart-header').html(cart_header);

        $('.cart-items .cart-item').remove();
        $('.cart-items').prepend(cart_items);

        $('.cwp-items .cwp-item').remove();
        $('.cwp-items').prepend(widget_items);

        if(widget_items){
            widget.removeClass('empty');
        }else{
            widget.addClass('empty');
        }

        $('.products-count').html(cart_total.quantity);
        $('.cart-total-price').html(cart_total.price_str);

        if(cart_total.quantity){
            $('.cwp-bottom').removeClass('empty');
        }else{
            $('.cwp-bottom').addClass('empty');
            cart.find('.cart-header, .recommendations').remove();
        }

        $('.products-value').text(widget_label);

        cart.removeClass('loading');
        widget.removeClass('loading');
    });

    fadeOutEnabled = true;
}
    
$(document).on('change', '.cart-quantity-input', function(){
    var elem = $(this);
    
    clearTimeout(cart_add_timer);
    cart_add_timer = setTimeout(function(){
        var id = elem.data('id'),
            quantity = elem.val(),
            result = {id, quantity};
    
        cart.addClass('loading');
        widget.addClass('loading');

        sendAjax( "update_cart_product", result, function(){
            updateCart();
        });
    }, 250);

});

$(document).on('click', '.remove-from-cart', function(){
    var elem = $(this),
        item = elem.parents('.cart-item'),
        id = elem.data('id');

    item.remove();
    cart.addClass('loading');
    widget.addClass('loading');

    sendAjax( "del_cart_product", id, function(){
        updateCart();
    });

});

$(document).on('click', '.cart-clean-btn', function(){
    cart.addClass('loading');
    widget.addClass('loading');

    simpleAjax( "clean_cart", function(){
        updateCart();
    });

});

$(document).on('change', '.ci-options input', function(){
    var elem = $(this),
        options = elem.parents('.ci-options'),
        product = options.data('product').toString(),
        checked = options.find('input:checked'),
        results = [];

    checked.each(function(){
        var item    = $(this),
            group   = item.data('group'),
            id      = item.data('id');

        results.push(group + ':' + id);
    });

    var clean_product = product;
    
    if(clean_product.indexOf('|') != -1){
        clean_product = clean_product.split('|')[0];
    }

    results = clean_product + '|' + results.join('.');

    cart.addClass('loading');
    widget.addClass('loading');
    var data = {
        from: product,
        to: results
    };

    sendAjax('change_price', data, function(){
        updateCart();
    });

});
