if($('.first-slide').length > 0){

	$('.fs-slider').slick({
		dots: false,
		arrows: true,
		prevArrow: '<button class="icon-left prev"></button>',
		nextArrow: '<button class="icon-right next"></button>',
		autoplay: true,
		rows: 0,
		autoplaySpeed: 5000,
		responsive: [
			{
			breakpoint: 1200,
				settings: {
					arrows: false
				}
			}
		]
	})
 
}