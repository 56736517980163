function loadItems(elem, settings){
    elem.addClass('loading');
    settings = settings.split(' ');

    jQuery.post('/wp-admin/admin-ajax.php', {
        action: 'get_items_by_category',
        post_type: settings[0],
        taxonomy: settings[1],
        tag: settings[2],
    },
        function (res) {
            elem.removeClass('loading');

            if(elem.hasClass('slick-initialized')){
                elem.slick('removeSlide', null, null, true);
                elem.slick('slickAdd',res);
                // elem.slick('setPosition');
            }else{
                elem.find('.catalog-item, .empty-block').remove();
                elem.append(res);
    
                if(elem.hasClass('items-slider')){
                    slider = elem.data('slider');
                    elem.slick(sliders[slider]);
                }
            }
        }
    );
}

$('[data-load-settings]').on('click', function(){
    var elem = $(this);

    if(!elem.hasClass('active')){
        elem.siblings().removeClass('active');
        elem.addClass('active');

        var container = elem.parents('.load-wrapper').find('.load-container'),
            settings = elem.data('load-settings');

        loadItems(container, settings);
    }

});

$('[data-load]').each(function(){
    var elem = $(this),
        settings = elem.data('load');

    loadItems(elem, settings);
})

$(document).on('click', '.cat-arrow', function(e){
    var elem = $(this),
        parent = elem.parent(),
        dropdown = parent.next();

    dropdown.stop();

    if(parent.hasClass('show')){
        parent.removeClass('show');
        dropdown.slideUp(300);
    }else{
        parent.addClass('show');
        dropdown.slideDown(300);
    }

    e.preventDefault();
})