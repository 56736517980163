formFuncs['oneClickOrder'] = function(){
    var form = $('.one-click-form'),
        results = get_fields(form),
        btn = $('#oc_submit'),
        add_to_cart = $('.add-to-cart'),
        quantity = $('.ci-quantity-input').val(),
        product = add_to_cart.data('id'),
        products = {};

    if(add_to_cart.data('selected')){
        product += '.' + add_to_cart.attr('data-selected');
    }

    products[product] = quantity;

    results['delivery'] = 2;
    results['address'] = '';
    results['comment'] = '';
    results['cash_sum'] = 0;
    results['delivery_price'] = 0;
    results['payment'] = 0;
    results['products'] = products;

    btn.addClass('thinks');

    sendAjax('add_order', results, function(res){
        window.location.href = '/success';
    })

};