fadeOutEnabled = true;

function clickHandler(){
    var elem = $(this),
        parent = elem.data('parent'),
        item = elem.data('item');
    
    if(item == 'self'){
        item = elem;
    }else if(item == 'parent'){
        item = elem.parents('.' + parent);
    }else{

        if(parent){
            item = elem.parents('.' + parent).find('.' + item);
        }else{
            item = $('.' + item);
        }

    }

    item.stop();

    if(elem.hasClass('click-fadeout')){
        item.fadeOut(200);
        elem.removeClass('active');
    }

    if(elem.hasClass('click-fade')){
        item.fadeIn(200);
        elem.addClass('active');
    }

    if(elem.hasClass('click-toggleslide')){
        item.slideToggle(200);
        elem.toggleClass('active');
    }

    if(elem.hasClass('slideup-other')){
        items = elem.parents('.' + parent).siblings().find('.' + elem.data('item'));

        items.stop();
        items.slideUp(200);
    }

    if(elem.hasClass('outside-fadeout')){

        $(document).click(function (e){
            if (!item.is(e.target) && item.has(e.target).length === 0) {
                item.fadeOut(200);
            }
        });

    }

    if(elem.hasClass('reset-form')){
        item.find('input, textarea').each(resetInput);
    }

}

function outsideHandler(){
    var elem = $(this),
        parent = elem.data('parent'),
        item = elem.data('item');
    
    if(item == 'self'){
        item = elem;
    }else if(item == 'parent'){
        item = elem.parents('.' + parent);
    }else if(parent == 'self'){
        item = elem.find('.' + item);
    }else{
        if(parent){
            item = elem.parents('.' + parent).find('.' + item);
        }else{
            item = $('.' + item);
        }
    }

    if(elem.hasClass('outside-fadeout')){

        $(document).click(function (e){
            if (!elem.is(e.target) && elem.has(e.target).length === 0) {
                item.fadeOut(200);
            }
        });

    }

}

function hoverHandler(){
    var elem = $(this),
        parent = elem.data('parent'),
        item = elem.data('item');
    
    if(item == 'self'){
        item = elem;
    }else if(item == 'parent'){
        item = elem.parents('.' + parent);
    }else{

        if(parent){
            item = elem.parents('.' + parent).find('.' + item);
        }else{
            item = $('.' + item);
        }

    }

    item.stop();

    if(elem.hasClass('hover-fade')){
        item.fadeIn(200);
    }

}

function outHandler(){

    if(fadeOutEnabled){
        var elem = $(this),
            parent = elem.data('parent'),
            item = elem.data('item');
        
        if(parent){
            item = elem.parents('.' + parent).find('.' + item);
        }else{
            item = $('.' + item);
        }

        item.stop();

        if(elem.hasClass('out-fadeout')){
            item.fadeOut(200);
        }
    }

}

function checkHandler(){
    var elem = $(this),
        parent = elem.data('parent'),
        item = elem.data('item');

    if(parent){
        item = elem.parents('.' + parent).find('.' + item);
    }else{
        item = $('.' + item);
    }

    item.stop();

    if(elem.hasClass('check-toggleslide')){
        
        if(elem.prop('checked')){
            item.slideDown(300);
        }else{
            item.slideUp(300);
            item.removeClass('error');
            item.find('.error').removeClass('error');
        }

    }

}

$('.click-handler').on('click', clickHandler);
$('.hover-handler').on('mouseenter', hoverHandler);
$('.out-handler').on('mouseleave', outHandler);
$('.outside-handler').each(outsideHandler);

$(document).on('change', 'input[type="checkbox"], input[type="radio"]', function(){
    $('.check-handler').each(checkHandler);
})