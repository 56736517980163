var formFuncs = {};

function fields_array(elem, attr, is_required){
    var results = {};

    if(is_required){
        var inputs = elem.find('input.required, textarea.required');
    }else{
        var inputs = elem.find('input, textarea');
    }

    inputs.each(function(){
        var elem = $(this),
            name = elem.attr(attr),
            type = elem.attr('type'),
            val = $.trim(elem.val());
        
        if(type == 'checkbox' || type == 'radio'){
            results[name] = elem.prop('checked');
        }else{
            results[name] = val;
        }

    })

    return results;
}

function get_fields(elem){
    return fields_array(elem, 'name', false);
}

function get_fields_auth(elem){
    return fields_array(elem, 'id', true);
}

function get_fields_by_id(elem){
    return fields_array(elem, 'id', false);
}

$(document).on('click', '.bind-to-form', function(e){
    var elem = $(this),
        forms = elem.data('form').split(', '),
        func = elem.data('success'),
        errors;

    e.preventDefault();

    if(!elem.hasClass('thinks')){

        $.each(forms, function(index, form){
            form = $('.' + form);
    
            if(form.hasClass('required')){
                form.each(inputCheck);

                if(form.hasClass('error')){
                    errors = $.extend(errors, form);
                }
    
            }

            form.find('.required:visible, .required:checkbox').each(inputCheck);
                
            if(form.find('.error').length > 0){
                errors = $.extend(errors, form.find('.error'));
            }
    
        })
    
        if(!errors || errors == 'undefined'){
            formFuncs[func]();
        }else if(elem.hasClass('scroll-to-error')){
            scrollTo(errors.eq(0));
        }

    }

})