$(document).on('click', '.add-to-cart', function(){
    var elem = $(this);

    if(!elem.hasClass('thinks')){
        var id = elem.data('id'),
            parent = elem.parent(),
            quantity = parseInt(elem.siblings('.quantity-field').children('input').val()),
            append_btn = true,
            selected = elem.attr('data-selected');

        if(selected){
            id += '.' + selected;
        }
        
        if(isNaN(quantity)){
            append_btn = false;
            quantity = elem.data('quantity');
        }

        var result = { id, quantity };

        elem.addClass('thinks');

        if(cart.length > 0){
            cart.addClass('loading');

            sendAjax( "add_to_cart", result, function(){
                updateCart();
                elem.removeClass('thinks');
            });

        }else{
            sendAjax( "add_to_cart", result, function(){

                if(append_btn){
                    updateCart();
                    elem.removeClass('thinks');
                    elem.addClass('green');
                }else{
                    updateCart();
                    elem.removeClass('thinks');
                    elem.addClass('green');
                }

            } );
        }
        
    }
});

$(document).on('change', '.ci-quantity input, .single-quantity input', function(){
    var elem = $(this),
        total = elem.parents('.item-content, .product-info').find('.product-total-price'),
        price = parseInt(elem.attr('data-price')),
        quantity = minmax(parseInt(elem.val()), 0, 50);

    total.find('span').text((price * quantity).toLocaleString())
    total.stop();

    if(quantity > 1){
        total.slideDown(200);
    }else{
        total.slideUp(200);
    }
});

$(document).on('click', '.select-prices .tab', function(){
    var elem = $(this),
        siblings = elem.siblings(),
        item = elem.parents('.catalog-item, .product-wrapper'),
        price_el = item.find('span.price'),
        old_price_el = item.find('del.old-price'),
        discount_el = item.find('.discount-tag'),
        economy_el = item.find('.economy-tag'),
        input = item.find('.ci-quantity-input'),
        btn = item.find('.add-to-cart'),
        price = elem.data('price'),
        price_f = elem.data('price-f'),
        price_fc = elem.data('price-fc'),
        economy = elem.data('economy'),
        discount = elem.data('discount'),
        old_price = elem.data('old-price'),
        id = elem.data('id'),
        wrapper = elem.parents('.select-prices');

    if(wrapper.hasClass('update')){
        cart.addClass('loading');
        widget.addClass('loading');
        var data = {
            from: wrapper.data('id'),
            to: id
        };

        fadeOutEnabled = false;

        sendAjax('change_price', data, function(){
            updateCart();
        });

    }

    siblings.removeClass('active');
    elem.addClass('active');
    price_el.text(price_fc);
    old_price_el.text(old_price);
    discount_el.text(discount);
    economy_el.text(economy);
    input.attr('data-price', price);
    btn.attr('data-selected', id);

    input.change();
});