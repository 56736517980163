function inputCheck(elem){

    var elem   = $(this),
        type   = elem.data('type'),
        parent = elem.parents('.form-group');    

    if(elem.is('input') || elem.is('textarea')){
        var val = elem.val();
    }

    if(parent.length == 0){
        parent = elem.parents('.ask-line');
    }

    if(elem.is('input textarea')){
        var val = elem.val();
    }

    if(!type || type == 'undefined'){
        type = 'text';
    }
    
    parent.removeClass('error');
    parent.find('.error-message').remove();

    function message(text){        
        if(!text){
            text = 'заполните поле';
        }
        
        parent.append('<p class="error-message">' + text + '</p>');
    }
        
    function type_name(){
        if(val == ''){
            parent.addClass('error');
            message();
        }
    }

    function type_delivery_price(){
        var max = elem.data('max'),
            min = parseInt($('.parametrs-list .total').attr('data-total')),
            val = parseInt(elem.val());

        if(isNaN(val)){
            val = 0;
        }
        
        if(val < min){
            parent.addClass('error');
            message('Сумма меньше стоимости заказа');
        }

        if(val > max){
            parent.addClass('error');
            message('Сумма слишком велика');
        }

    }

    function type_surname(){
        if(val == ''){
            parent.addClass('error');
            message();
        }    
    }

    function type_phone(){
        if( val.indexOf('_') > 0 ){
            parent.addClass('error');
            message();
        }else{
            for( var i = 0; i < 10; i++){
                var check = val.split(i).length - 1;
                if(check > 9){
                    parent.addClass('error');
                    message('введите номер корректно');
                }
            }
        }
    
    }

    function type_cart_sum(){
        var sum = $('.cart-info .cart-total-price').text() - 1;
        
        if(val == ''){
            parent.addClass('error');
            message('введите сумму');
        }else if(val < sum){
            parent.addClass('error');
            message('сумма меньше цены заказа');
        }
    }

    function type_select(){            
        var selected = elem.data('id');

        if(!val || !selected){
            parent.addClass('error');
            message('выберите что-то');
        }
    }

    function type_radio(){
        var selected = parent.find('input:checked').length;

        if(selected == 0){
            parent.addClass('error');
            message('выберите что-то');
        }
    }

    function type_text(){     
        if(val == ''){
            parent.addClass('error');
            message();
        }
    }

    function type_email(){        
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,
            emailTest = regex.test(val);

        if(!emailTest && val == ''){
            parent.addClass('error');
            message('введите email корректно');   
        }else if(!emailTest){
            parent.addClass('error');
            message('введите email');   
        }
    }

    function type_checkbox(){
        parent.find('.error-message').remove();

        if(!elem.prop('checked')){
            parent.addClass('error');
            parent.addClass('animate');

            setTimeout(function(){
                parent.removeClass('animate');
            }, 400)

            if(parent.hasClass('ask-question-checkbox')){
                message('вы не приняли условия соглашения'); 
            }

        }
        elem.click(function(){
            parent.removeClass('error');
        })
    }

    function type_confirm_pass(){        
        var pass = $('input[type="password"]').eq(0);

        if(val == ''){
            parent.addClass('error');
            message();
        }else if(val != pass.val()){
            parent.addClass('error');
            message('пароли не совпадают');
        }
    }

    function type_radio_block(){
        var radios = elem.find('input:checked');
        elem.removeClass('error');

        if(radios.length == 0){
            elem.addClass('error');
        }

    }

    function type_map(){
        elem.removeClass('error');

        if(!inZone){
            elem.addClass('error');
        }

    }

    function type_review(){
        elem.removeClass('error');

        if(elem.find('.active').length != 1){
            elem.addClass('error');
        }

    }
    
    eval( 'type_' + type + '()' );

}

function resetInput(){
    var elem   = $(this),
        parent = elem.parents('.form-group'),
        type   = elem.data('type'),
        error  = parent.find('.error-message');

    elem.prop('selected', false);
    error.remove();
    parent.removeClass('error');

    if(type == 'phone'){
        var index = elem.data('index'),
            settings = phoneMask[index]['mask'],
            value = phoneMask[index]['default'];

        settings.value = value;
    }else if(type == 'review'){
        elem.find('.active').removeClass('active');
        elem.removeClass('active');
    }else{
        elem.val('');
    }
}

function displayErrors(array){
    $.each(array, function(key, value){
        if(key == 'errors'){
            $('.auth-content .message-block').remove();
            $('.auth-content .form-group').eq(0).before(print_msg('error', 'Ошибка', value));
            showMessage($('.auth-content .message-block'));
        }else{
            var elem = $('#' + key),
                parent = elem.parent();

            parent.addClass('error');
            parent.find('.error-message').remove();
            parent.append('<p class="error-message">' + value + '</p>');
        }
    })
}

$(document).on('change', '.radio-block input', function(){
    var elem = $(this);
    elem.parents('.radio-block').removeClass('error');
})

$(document).on('input', 'input, textarea', function(){
    var elem = $(this);
    elem.parents('.form-group').removeClass('error');
})