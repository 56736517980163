$('.settings-tabs .tab').click(function(){
    var elem = $(this),
        tabs = $('.settings-tabs .tab'),
        index = tabs.index(elem),
        content = $('.settings-content .tab-content'),
        active = $('.settings-tabs .tab.active, .settings-content .tab-content.active');

    active.removeClass('active');
    elem.addClass('active');
    content.eq(index).addClass('active');
})

$('.color-tabs .tab').click(function(){
    var elem = $(this),
        index = elem.index(),
        siblings = elem.siblings(),
        settings = $('.settings-body');

    siblings.removeClass('active');
    elem.addClass('active');
    settings.addClass('loading');

    requestAjax('set_color', index, function(){
        location.reload();
    });
})

$('.select-logo .logo').click(function(){
    var elem = $(this),
        index = elem.index(),
        siblings = elem.siblings(),
        settings = $('.settings-body');

    siblings.removeClass('active');
    elem.addClass('active');
    settings.addClass('loading');

    requestAjax('set_logo', index, function(){
        location.reload();
    });
})