$('.product-big-image').slick({
    dots: false,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<button class="icon-left prev"></button>',
    nextArrow: '<button class="icon-right next"></button>',
})

if($('.product-images-nav').length > 0){

    $('.product-big-image').slick('slickSetOption', {
        asNavFor: '.product-images-nav'
     }, true);

    $('.product-images-nav').slick({
        dots: false,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        centerMode: true,
        focusOnSelect: true,
        asNavFor: '.product-big-image'
    })

}