$('.reviews-slider').slick({
	dots: false,
	arrows: false,
	slidesToShow: 2,
	slidesToScroll: 1,
	responsive: [
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 1,
				adaptiveHeight: true
			}
		}
	]
})

$('.review-rating .rr-item').click(function(){
	var elem = $(this),
		parent = elem.parent(),
		items = parent.find('.rr-item');

	parent.removeClass('error');
	items.removeClass('active');
	elem.addClass('active');
})

formFuncs['confReview'] = function(){
	var form = $('.review-from'),
		btn = $('#ar_submit'),
		success = form.find('.success-screen'),
		index = $('.rr-item').index($('.rr-item.active').eq(0)) + 1,
		results = {
			'rating' 	: minmax(index, 1, 5),
			'review' 	: $.trim($('#ar_review').val())
		}

	btn.addClass('thinks');
		
	sendAjax('add_reviews', results, function(res){
		success.fadeIn(300);
		form.find('.required').each(resetInput);	
		btn.removeClass('thinks');	
	});
}