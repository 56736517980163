function update_fav(){
    var sidebar = $('.fav-sidebar'),
        header = sidebar.find('.fb-header'),
        items = sidebar.find('.fb-item'),
        count = $('.fav-count');

    sidebar.addClass('loading');
    simpleAjax( "get_fav_items", function(res){
        res = JSON.parse(res);
        items.remove();
        sidebar.removeClass('loading');
        header.after(res.items);
        count.text(res.count);
    });

}

update_fav();

function remove_fav(id){
    $('.add-to-fav[data-id="' + id + '"]').removeClass('active');
    sendAjax( "remove_fav", id, function(){
        $('.catalog-item[data-id="' + id + '"], .product-info').removeClass('thinks loading')
        update_fav();
    } );
}

function remove_all_fav(elem){
    if(elem){
        elem.attr('disabled', true);
    }
    $('.fixed-sidebar').find('.fb-item').remove();
    $('.add-to-fav').removeClass('active');
    simpleAjax( "remove_all_fav", function(){

        if(elem){
            elem.removeAttr('disabled');
            $('.fav-count').text(0);
        }

    } );
}

$(document).on('click', '.add-to-fav', function(){
    var elem = $(this),
        id = elem.data('id'),
        parent = elem.parents('.catalog-item');

    if(parent.length){
        parent.addClass('thinks');
        parent.addClass('regular');
        parent.removeClass('show');
    }else{
        parent = elem.parents('.product-info');
        parent.addClass('loading');
    }

    if(!elem.hasClass('active')){
        sendAjax( "add_to_fav", id, function(res){
            update_fav();
            parent.removeClass('thinks loading');
            $('.add-to-fav[data-id="' + id + '"]').addClass('active');
        } );
    }else{
        remove_fav(id);
    }

})

$(document).on('click', '.remove-from-fav', function(){
    var elem = $(this),
        item = elem.parents('.fb-item'),
        id = elem.data('id');

    item.remove();
    remove_fav(id);
})

$(document).on('click', '.clean-fav', function(){
    var elem = $(this);
    remove_all_fav(elem);
});