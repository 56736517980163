function scrollGradient(elem){
    var width = elem.offsetWidth,
        scrolWidth = elem.scrollWidth,
        scroll = elem.scrollLeft + width,
        parent = elem.parentNode;

    parent.classList.remove('left-fade', 'right-fade');

    if(width != scrolWidth){

        if(scroll != width){
            parent.classList.add('left-fade');
        }else{
            parent.classList.remove('left-fade');
        }

        if(scroll != scrolWidth){
            parent.classList.add('right-fade');
        }else{
            parent.classList.remove('right-fade');
        }

    }

}

$('.h-scroll').each(function(){
    var elem = $(this),
        slider = elem.children()[0];

    let isDown = false;
    let startX;
    let scrollLeft;

    scrollGradient(slider);

    slider.addEventListener('mousedown', (e) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
        if(!isDown) return;
        e.preventDefault();
        scrollGradient(slider);
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 1.3;
        slider.scrollLeft = scrollLeft - walk;
    });
    slider.addEventListener('scroll', (e) => {
        scrollGradient(slider);
    });
})