$('.login_confirm').click(function(e){
    var elem = $(this),
        auth = $('.auth-content');

    e.preventDefault();

    if(!elem.hasClass('thinks') || auth.find('.error').length){
        auth.find('.required').each(inputCheck);

        if(auth.find('.form-group.error').length == 0){
            var results = get_fields_auth(auth),
                msg = auth.find('.message-block');

            if(msg.length){
                removeMessage(auth.find('.message-block'));
            }

            elem.addClass('thinks');

            sendAjax('login_user', results, function(res){
                window.location.href = '/dashboard';
            },function(res){
                displayErrors(res.text_error);
                elem.removeClass('thinks');
            });
        }
    }
})