$(document).on('click', '.close-message-block', function(){
    var elem = $(this),
        parent = elem.parents('.message-block');

    removeMessage(parent);
})

function print_msg(status, title, text){
    var structure = `
        <div class="message-block ` + status + ` full">
            <div class="text">
                <h5>` + title + `</h5>
                <p>` + text + `</p>
            </div>
            <div class="close-message-block"></div>
        </div>
    `;

    return structure;
}

function showMessage(elem){
    var height = elem[0].offsetHeight,
        margin = parseInt(elem.css('marginBottom').replace(/[^0-9]/gm, '')),
        margin_top = '-' + (height + margin) + 'px';

    elem.css({
        marginTop: margin_top,
        opacity: 0,
    })
    elem.animate({
        marginTop: 0,
        opacity: 1
    }, 300);    
}

function removeMessage(elem){
    var height = elem[0].offsetHeight,
        margin = parseInt(elem.css('marginBottom').replace(/[^0-9]/gm, '')),
        margin_top = '-' + (height + margin) + 'px';

    elem.animate({
        marginTop: margin_top,
        opacity: 0
    }, 300);    

    setTimeout(function(){
        elem.remove();
    }, 300);
}