$(document).on('click', '#promo-btn', function(e){
    var elem = $(this),
        form = elem.parents('.form-group'),
        input = form.find('input'),
        val = input.val();

    e.preventDefault();
    if(val && !form.hasClass('error') && !elem.hasClass('thinks')){
        elem.focus();
        sendAjax(
            'send_promo',
            val,
            function(){
                form.addClass('success');
                updateCart();
            },
            function(){
                form.addClass('error');
            }
        )
    }
})

$(document).on('click', '#remove-promo-btn', function(e){
    var elem = $(this);

    e.preventDefault();
    if(!elem.hasClass('thinks')){
        elem.addClass('thinks');

        simpleAjax('remove_promo', function(){
            updateCart();
        });
    }
})