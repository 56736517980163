function showFixedBtns(){
    var scroll = $(window).scrollTop(),
        height = $('.site-header').height(),
        fixed_btns = $('.fixed-btns');

    if(scroll > height){
        fixed_btns.addClass('show');
    }else{
        fixed_btns.removeClass('show');
    }
}

$(document).on('click', '.to-up',function(){
    $('html, body').animate({scrollTop: 0}, 600);
});

if($('body').hasClass('archive')){
    $('.fixed-btns').addClass('show');
}else{
    showFixedBtns();

    $(window).on('scroll resize', function(){
        showFixedBtns();
    })
}