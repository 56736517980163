$('#ep_confirm').click(function(e){
    var elem = $(this),
        form = $('#ep_form'),
        body = $('.dashboard-body');

    e.preventDefault();

    if(!elem.hasClass('thinks') || form.find('.error').length){
        form.find('.required').each(inputCheck);

        if(form.find('.error').length == 0){
            var results = get_fields_by_id(form);

            results['address'] = deliveryAddress;

            if(deliveryCoords){
                results['coords'] = deliveryCoords;
            }else{
                results['coords'] = '';
            }

            elem.addClass('thinks');
            form.find('.message-block').remove();

            sendAjax('edit_profile', results, function(res){
                body.find('.message-block').remove();
                body.prepend(print_msg('success', 'Успех', 'Ваши данные были успешно сохранены'));
                scrollTo(body);

                elem.removeClass('thinks');           
            },function(res){
                displayErrors(res.text_error);
                elem.removeClass('thinks');
            });
        }

    }
})