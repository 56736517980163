var phoneMask = {};

function phone_mask(){
    phoneMask = [];
    var items = document.getElementsByClassName('phone-mask'),
        index = 0;

    Array.prototype.forEach.call(items, function (element) {
        phoneMask[index]['mask'] = new IMask(element, {
            mask: '+{7} (000) 000-00-00',
            lazy: false,  // make placeholder always visible
            placeholderChar: '_'     // defaults to '_'
        });

        phoneMask[index]['default'] = element.value;

        index++;
    });
    
}

function date_mask(){
    var items = document.getElementsByClassName('date-mask');

    Array.prototype.forEach.call(items, function (element) {
        new IMask(element, {
            mask: Date,
            lazy: false,
            placeholderChar: '_'     // defaults to '_'
        });
    });
}

$(document).ready(function () {
    
    var check = $('.phone-mask').length;   

    if( check > 0 ){

        var items = document.getElementsByClassName('phone-mask');

        var items = document.getElementsByClassName('phone-mask'),
        index = 0;

        Array.prototype.forEach.call(items, function (element) {
            phoneMask[index] = [];
            phoneMask[index]['mask'] = new IMask(element, {
                mask: '+{7} (000) 000-00-00',
                lazy: false,  // make placeholder always visible
                placeholderChar: '_'     // defaults to '_'
            });

            element.setAttribute('data-index', index);
            phoneMask[index]['default'] = element.value;

            index++;
        });

        $('.phone-mask').each(function(){
            var elem = $(this);

            elem.attr('placeholder', elem.val());
        });

    }
    
    var check = $('.date-mask').length;   

    if( check > 0 ){

        var items = document.getElementsByClassName('date-mask');

        Array.prototype.forEach.call(items, function (element) {
            new IMask(element, {
                mask: Date,
                lazy: false,
                placeholderChar: '_'     // defaults to '_'
            });
        });


    }

    var check = $('.price-mask').length;

    if( check > 0 ){

        var items = document.getElementsByClassName('price-mask');

        Array.prototype.forEach.call(items, function (element) {
            var min = element.dataset.min,
                max = element.dataset.max;

            phoneMask = new IMask(element, {
                mask: Number,
                min: min,
                max: max,
                thousandsSeparator: ''
            });
        });

    }

    $(document).on('input', '.name-mask', function(){
        var elem = $(this),
            val  = elem.val().replace(/[^a-zA-Z0а-яА-ЯГгҚқҒғӘәҢңӨөҰұҮүҺһІіЭэ\- ]/gi, ''),
            val  = val.substr(0, 24);        

        elem.val(val);
    });
    
});