formFuncs['sendRequestCall'] = function(){
    var btn = $('#rc-submit'),
        form = $('.request-call-form'),
        results = get_fields(form),
        success = form.find('.success-screen');

    results['message'] = '';
    results['email'] = '';
    results['method'] = 0;

    btn.addClass('thinks');

    sendAjax('send_mail', results, function(res){
		success.fadeIn(300);
		form.find('.required').each(resetInput);	
		btn.removeClass('thinks');	
	});
}