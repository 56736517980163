function resultPopup(status, header, title, message){
    $('.popup').fadeOut(300);

    var popup  = $('.popup.result-popup'),
        popup_header = popup.find('.popup-header span'),
        popup_title = popup.find('h4'),
        popup_text   = popup.find('p');

    popup.removeClass();
    popup.addClass('popup result-popup');
    popup.addClass(status);
    popup_header.text(header);
    popup_title.text(title);
    popup_text.text(message);
    popup.fadeIn(300);
}

$(document).ready(function () {
    
    $('.popup-blackboard, .close-popup').click(function(){
        var popup = $(this).parents('.popup'),
            success = popup.find('.success-screen');

        if(popup.hasClass('error-popup')){
            $('.error-code').slideUp(300);  
        }

        success.fadeOut(300);
        popup.fadeOut(300);
    });

    $('.open-popup').click(function(){
        var link = $(this).data('popup');
        $('.popup').fadeOut(300);
        $('.popup.' + link).fadeIn(300);
    });

    $('.show-code').click(function(){
        $('.error-code').slideToggle(300);
    })

    $('.close-error-popup').click(function(){
        $('.error-code').slideUp(300);
        $('.popup').fadeOut(300);
    })

});