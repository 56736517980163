$(document).on('click', '.archive-sort .sort-item', function(){
    var elem = $(this),
        direction = elem.attr('data-direction'),
        siblings = elem.siblings();

    siblings.removeClass('active');
    siblings.removeAttr('data-direction');

    elem.addClass('active');
    if(direction == 'ASC'){
        elem.attr('data-direction', 'DESC');
    }else if(direction == 'DESC'){
        elem.removeClass('active');
        elem.removeAttr('data-direction');
    }else{
        elem.attr('data-direction', 'ASC');
    }
})